import * as React from "react";
import classNames from 'classnames';

import RawHtml from "../RawHtml";

import s from './CardTextCta.module.scss';
import { Image as ImageType } from "../../types";
import Image from "../Image";

export interface CardTextCtaProps {
    title: string,
    description?: string,
    ctaTextLeft?: string,
    ctaTextRight?: string,
    image?: ImageType,
    pageType: string
}

const CardTextCta: React.FC<CardTextCtaProps> = (props) => {
    const { title, description, ctaTextLeft, ctaTextRight, image, pageType } = props;

    const rootClasses = classNames(s.Root, {
        [s[`Root--${pageType}`]]: !!pageType
    });

    let mediaQueries = [
        { minWidth: 960, src: image?.renditions?.small.src },
        { minWidth: 768, src: image?.renditions?.large.src },
        { minWidth: 480, src: image?.renditions?.medium.src },
        { minWidth: 360, src: image?.renditions?.small.src },
    ];

    mediaQueries = mediaQueries.filter((x) => x.src);

    return (
        <article className={rootClasses}>
            <div className={s.Header}>
                <h2 className={s.Title}>{title}</h2>
                {description && <div className={s.Text}>{description}</div>}
            </div>
            <div className={s.CtaWrapper}>
                <div className={s.CtaLeft}>
                    <RawHtml html={ctaTextLeft} />
                </div>
                <div className={s.CtaRight}>
                    <RawHtml html={ctaTextRight} />
                </div>

                {image && (
                    <Image
                        containerClass={s.ImageWrap}
                        className={s.Image}
                        image={image.renditions?.thumb || image}
                        mediaQueries={mediaQueries}
                    />
                )}
            </div>
        </article>
    );
};

export default CardTextCta
